/* ---------------------------------
Faq
--------------------------------- */

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
} from "@chakra-ui/react";
import React from "react";
import { faqList } from "../../../content/data/faq";
import { MenuIds } from "../../../types/types";
import { Headline } from "../../Headline";
import Section from "../../Section";

const Faq: React.FC = () => {
  return (
    <Section
      id={MenuIds["F.A.Q."]}
      textAlign={"center"}
      containerProps={{ maxW: "1088px", px: ["1rem", "2rem"] }}
    >
      <Headline>F.A.Q.</Headline>

      <Accordion allowToggle allowMultiple defaultIndex={[0]}>
        {faqList?.map(({ section, list }, i) => (
          <AccordionItem key={i}>
            <AccordionButton py={6}>
              <Headline
                as="h4"
                fontFamily={"body"}
                textAlign="left"
                fontSize="lg"
                flex={"1"}
                m={0}
              >
                {section}
              </Headline>
              <AccordionIcon boxSize={"30px"} mr={[4, 8]} />
            </AccordionButton>
            <AccordionPanel textAlign="justify" color={"gray.600"} px={[4, 8]}>
              <Accordion allowToggle flexBasis={[0, "60%"]} bg={"utils.white"}>
                {list.map(({ title, body }, k) => (
                  <AccordionItem _last={{ borderBottom: 0 }} key={k}>
                    <AccordionButton _expanded={{}} padding={0}>
                      <Box
                        flex={"1"}
                        textAlign="left"
                        fontSize="md"
                        fontWeight={"bold"}
                        _hover={{ color: "accent.01" }}
                        px={[4, 8]}
                        py={6}
                      >
                        {title}
                      </Box>

                      <AccordionIcon boxSize={"30px"} mr={[4, 8]} />
                    </AccordionButton>

                    <AccordionPanel
                      textAlign="justify"
                      color={"gray.600"}
                      px={[4, 8]}
                    >
                      {body}
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Section>
  );
};

export default Faq;
