import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Image,
  SimpleGrid,
  Divider,
  Link,
} from "@chakra-ui/react";
import React from "react";
import { data } from "../../../content/data/prodotti-servizi";
import { Headline } from "../../Headline";
import Section from "../../Section";
import { TextParagraph } from "../../TextParagraph";
import HyundaiLogo from "../../../img/320px-Hyundai_Motor_Company_logo.png";
import KiaLogo from "../../../img/logo_kia_black-rbr.png";

export interface IProdottiData {
  title: React.ReactElement | string;
  body: React.ReactElement | string;
  image?: string;
}

export const ProdottiServizi: React.FC = () => (
  <>
    <Section id="prodotti-e-servizi" bg={"utils.white"}>
      <Headline>Prodotti e servizi</Headline>

      <Accordion
        allowToggle
        allowMultiple
        defaultIndex={[]}
        flexBasis={[0, "60%"]}
      >
        {data?.map?.(({ title, body, image }, i) => (
          <AccordionItem _last={{ borderBottom: 0 }} key={i}>
            <AccordionButton _expanded={{}}>
              <Box flex={"1"} textAlign="left" fontSize="lg">
                {title}
              </Box>

              <AccordionIcon boxSize={"30px"} />
            </AccordionButton>

            <AccordionPanel textAlign="justify" color={"gray.600"} my={3}>
              {image ? (
                <>
                  <Image src={image} float="right" maxW="50%" ml={10} mb={10} />
                  <Box>{body}</Box>
                </>
              ) : (
                body
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Section>

    <Section bg={"utils.white"} containerProps={{ pb: 0 }}>
      <SimpleGrid minChildWidth="200px" spacing={50}>
        <Box>
          <Link
            href="https://www.hyundai.com/it/Acquisto/Promozioni/promozioni-privati.html"
            isExternal
          >
            <Box h={"48px"}>
              <Image
                src={HyundaiLogo}
                display={"block"}
                maxH={"100%"}
                m={"0 auto"}
              />
            </Box>
          </Link>
          <TextParagraph py="10" textAlign={"center"}>
            Per consultare le promozioni Hyundai{" "}
            <Link
              href="https://www.hyundai.com/it/Acquisto/Promozioni/promozioni-privati.html"
              isExternal
              color={"accent.01"}
            >
              clicca qui.
            </Link>
          </TextParagraph>
        </Box>

        <Box>
          <Link
            href="https://www.kia.com/it/guida-all-acquisto/promozioni/"
            isExternal
            display={"block"}
          >
            <Box h={"48px"}>
              <Image
                src={KiaLogo}
                display={"block"}
                maxH={"100%"}
                m={"0 auto"}
              />
            </Box>
          </Link>
          <TextParagraph py="10" textAlign={"center"}>
            Per consultare le promozioni KIA{" "}
            <Link
              href="https://www.kia.com/it/guida-all-acquisto/promozioni/"
              isExternal
              color={"accent.01"}
            >
              clicca qui.
            </Link>
          </TextParagraph>
        </Box>
      </SimpleGrid>
      <Divider />
    </Section>
  </>
);
