import { Box, Center, Image, Link, SimpleGrid } from "@chakra-ui/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";
import { Headline } from "../../../Headline";
import { TextParagraph } from "../../../TextParagraph";

type OwnProps = {
  image?: string;
  timestamp: string;
  title: string;
  body: string;
  buttonLabel?: string;
  buttonAction?: (e: React.MouseEvent) => void;
  buttonNavUrl?: string;
} & Record<string, unknown>;

export const NewsItem: React.FC<OwnProps> = ({
  image,
  timestamp,
  title,
  body,
  buttonLabel = "Scopri",
  buttonAction,
  buttonNavUrl,
  ...props
}) => {
  return (
    <SimpleGrid
      minChildWidth="250px"
      as={"article"}
      bg={"utils.offWhite"}
      mb={5}
      alignItems="center"
      {...props}
    >
      {image && (
        <Center>
          <Image src={image} alt={body.substr(0, 10)} />
        </Center>
      )}

      <Box p={5}>
        <Headline
          as={"h3"}
          fontSize="md"
          fontFamily={"body"}
          textAlign="left"
          mb={3}
          color="accent.01"
        >
          {title}
        </Headline>

        <TextParagraph>{body}</TextParagraph>

        <Link
          as={GatsbyLink}
          to={buttonNavUrl}
          float="right"
          textAlign={"center"}
          border="1px"
          borderColor="accent.03"
          color="accent.03"
          h={"auto"}
          py={3} // => 'n' * 4 px
          px={4} // => 'n' * 4 px
          textTransform={"uppercase"}
          onClick={buttonAction}
          _hover={{
            textDecoration: "none",
            bg: "hover.02",
          }}
        >
          {buttonLabel}
        </Link>
      </Box>
    </SimpleGrid>
  );
};
