import React from "react";

type THideUntil = {
  /**
   * Date string, format as yyyy-mm-dd
   */
  date: string;
};

/**
 * Show children elements only when `date` has passed.
 * Use to hide elements that must be published after a specific date
 */
export const HideUntil: React.FC<THideUntil> = ({ children, date }) => {
  try {
    const now = new Date();
    const publishOn = new Date(date);
    const show = now > publishOn;
    if (show) {
      return <>{children}</>;
    }
    return null;
  } catch (e) {
    console.warn("HideUntil: date is not a valid Date string");
    return null;
  }
};
