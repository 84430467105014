import React from "react";
import styled from "@emotion/styled";

const QuotationMarks = styled.span`
  position: relative;
  top: -8px;
  display: block;
  height: 40px;
  padding: 0 8px;
  font-size: 80px;
  font-weight: bold;
  line-height: 100%;
  vertical-align: middle;
`;

export const Quote: React.FC = ({ children }) => (
  <>
    <QuotationMarks>“</QuotationMarks>
    {children}
    <QuotationMarks>”</QuotationMarks>
  </>
);
