export const geolocation = (): Promise<google.maps.LatLngLiteral> => {
  return new Promise<google.maps.LatLngLiteral>((resolve, reject) => {
    if (!navigator.geolocation) {
      reject();
    }

    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      () => {
        reject();
      },
      {
        maximumAge: 60000,
        timeout: 5000,
        enableHighAccuracy: true,
      }
    );
  });
};

/**
 * Search an address on Google Geocoder.
 * @param {string} address - the address you're looking for
 * @returns {Promise<google.maps.GeocoderResult[]>} A promise which contains an array of GeocoderResult
 */
export const geocodeAddress = (
  address: string
): Promise<google.maps.GeocoderResult[]> =>
  new Promise<google.maps.GeocoderResult[]>((resolve, reject) => {
    const geocoderInstance = new google.maps.Geocoder();

    if (!geocoderInstance) {
      reject();
    }

    geocoderInstance.geocode({ address }, results => {
      if (results?.length > 0) {
        resolve(results);
      }

      reject();
    });
  });

/**
 * Converts degrees to radians
 * @param deg
 */
export const deg2rad = (deg: number): number => deg * (Math.PI / 180);

/**
 * Get the distance (in km) from two points (a, b) using haversine distance algorithm
 * @param a
 * @param b
 */
export const getDistanceBetweenPoints = (
  a: google.maps.LatLngLiteral,
  b: google.maps.LatLngLiteral
) => {
  const R = 6371.071; // Radius of the Earth in km
  const rlat1 = deg2rad(a.lat);
  const rlat2 = deg2rad(b.lat);
  const difflat = rlat2 - rlat1; // Radian difference (latitudes)
  const difflon = deg2rad(b.lng - a.lng); // Radian difference (longitudes)

  return (
    2 *
    R *
    Math.asin(
      Math.sqrt(
        Math.sin(difflat / 2) * Math.sin(difflat / 2) +
          Math.cos(rlat1) *
            Math.cos(rlat2) *
            Math.sin(difflon / 2) *
            Math.sin(difflon / 2)
      )
    )
  );
};

/**
 * Given a center point and a FIXED ZOOM LEVEL we receive a more-or-less-true
 * bounds in the LatLngBoundsLiteral format.
 *
 * This uses some magic numbers that were obtained with a little of math
 * explained here:
 * If we know that the bounds are for example n:10, e:20, s:20, w:0,
 * we can easily get that the deltas are (lat:5, lng:10)
 * by doing:     lat: (s-n)/2, lng: (e-w)/2
 *
 * By adding this boundsDelta to the center, we can extimate our bounds
 * (with the FIXED zoom value of 12 (our case)) even if the geolocation
 * function knows NOTHING about maps.
 *
 * We got this values by geolocating and then moving the map a few pixel,
 * just to trigger the onMove callback from the map, which happens to
 * have bounds :)
 *
 * @param center {google.maps.LatLngLiteral}
 * @returns {google.maps.LatLngBoundsLiteral}
 */
export const getFakeBounds = (
  center: google.maps.LatLngLiteral
): google.maps.LatLngBoundsLiteral => {
  // [!] IMPORTANT: This values are valid only if the zoom level is 12.
  // If the zoom is changed, then this values needs to be changed, too.
  const boundsDelta = {
    lat: 0.07467117254978817, // [!]
    lng: 0.12258768081665039, // [!]
  };

  return {
    north: center.lat - boundsDelta.lat,
    east: center.lng + boundsDelta.lng,
    south: center.lat + boundsDelta.lat,
    west: center.lng - boundsDelta.lng,
  };
};
