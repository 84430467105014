import React from "react";
import { useState } from "react";
import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import InfoWindow from "react-google-maps/lib/components/InfoWindow";
import { WithGoogleMapProps } from "react-google-maps/lib/withGoogleMap";
import { IFiliale } from "../../../../types/dealers";
import StoreInfoWindow from "./StoreInfoWindow";
import { FilterKeys } from "./EnabledStoreLocator";
import markerCluster from "../../../../img/marker-cluster.svg";
import h_marker from "../../../../img/H.svg";
import k_marker from "../../../../img/K.svg";

export type GMapsMouseEvent =
  | google.maps.MapMouseEvent
  | google.maps.IconMouseEvent;

export interface IMapCoords {
  center: google.maps.LatLngLiteral;
  bounds: google.maps.LatLngBoundsLiteral;
  zoom: number;
}

interface IProps extends WithGoogleMapProps {
  center?: google.maps.LatLng | google.maps.LatLngLiteral;
  onClick?: (e: GMapsMouseEvent) => void;
  onMapMove?: (newCoords: IMapCoords) => void;
  stores?: IFiliale[];
  zoom?: number;
}

const GMap: React.FC<IProps> = ({
  center,
  onClick,
  onMapMove,
  stores = [],
  zoom,
}) => {
  const mapInstance = React.useRef<google.maps.Map>();
  const [infoWindowOpen, setInfoWindowOpen] = useState<number | undefined>(
    undefined
  );

  const clickHandler = (e: GMapsMouseEvent) => {
    if (onClick) {
      onClick(e);
    }
  };

  const mapChangeHandler = () => {
    if (mapInstance.current !== null) {
      if (onMapMove) {
        const mapCoords = {
          center: mapInstance.current.getCenter().toJSON(),
          bounds: mapInstance.current.getBounds().toJSON(),
          zoom: mapInstance.current.getZoom(),
        };
        onMapMove(mapCoords);
      }
    }
  };

  const openInfoWindowHandler = (storeID: number) => (): void => {
    setInfoWindowOpen(storeID);
  };

  const closeInfoWindowHandler = () => {
    setInfoWindowOpen(undefined);
  };

  return (
    <GoogleMap
      ref={mapInstance}
      center={center}
      zoom={zoom}
      onClick={clickHandler}
      onCenterChanged={mapChangeHandler}
      onZoomChanged={mapChangeHandler}
      defaultZoom={6}
      defaultCenter={{ lat: 41.29085, lng: 11.2297381 }}
      defaultMapTypeId="roadmap"
      defaultOptions={{
        gestureHandling: "cooperative",
        mapTypeControl: false,
        fullscreenControl: false,
      }}
    >
      <MarkerClusterer
        averageCenter
        enableRetinaIcons
        gridSize={60}
        styles={[
          {
            anchorIcon: [30, 15],
            height: 30,
            textColor: "#ffffff",
            url: markerCluster,
            textSize: 14,
            width: 30,
          },
        ]}
      >
        {stores.map((store, i) => (
          <Marker
            key={i}
            icon={{
              scaledSize: { width: 40, height: 40 },
              url:
                store.tipo === FilterKeys.hyundai
                  ? h_marker
                  : store.tipo === FilterKeys.kia
                  ? k_marker
                  : undefined, // uses the generic marker
            }}
            position={{ lat: store.latitudine, lng: store.longitudine }}
            onClick={openInfoWindowHandler(Number(store.id))}
          >
            <>
              {infoWindowOpen && infoWindowOpen === Number(store.id) ? (
                <InfoWindow onCloseClick={closeInfoWindowHandler}>
                  <StoreInfoWindow store={store} />
                </InfoWindow>
              ) : null}
            </>
          </Marker>
        ))}
      </MarkerClusterer>
    </GoogleMap>
  );
};

const StoreLocatorMap = withScriptjs(withGoogleMap(GMap));

export default StoreLocatorMap;
