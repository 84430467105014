import { Icon } from "@chakra-ui/react";
import React from "react";

const NavigationIcon: React.FC = props => {
  return (
    <Icon viewBox="0 0 24 24" {...props}>
      <g fill="none" stroke="currentColor" strokeWidth="1.3">
        <path d="M12 21a9 9 0 110-18 9 9 0 010 18zM17 12.5h7M0 12.5h7M12 17v7M12 0v7" />
      </g>
    </Icon>
  );
};

export default NavigationIcon;
